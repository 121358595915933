<!-- <template>
  <scatter-chart :chartData="chartData" :options="chartOptions"></scatter-chart>
</template> -->

<script>
import { Scatter, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;

export default {
  extends: Scatter,
  mixins: [reactiveProp],
  components:{
    ScatterChart:Scatter
  },
  data() {
    return {
      chartOptions: {
        scales: {
          x: {
            type: 'linear',
            position: 'bottom'
          },
          y: {
            type: 'linear',
            position: 'left'
          }
        }
        // Add more chart options as needed
      }
    };
  },
  mounted() {
    this.renderChart(this.chartData, this.chartOptions);
  }
};
</script>
