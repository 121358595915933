<!-- <bar-chart></bar-chart> -->
<!-- <template>
</template> -->

<script>
import { Bar, Chart } from "vue-chartjs";

export default {
  extends: Bar,
  mounted() {
    this.renderChart({
      // Chart configuration options
      labels: ["Total Ventes", "Payé", "Impayé", "Règlement "],
      datasets: [
        {
          label: "Ventes",
          data: [12, 19, 3, 5],
          backgroundColor: [
            "rgba(255, 99, 132, 0.2)",
            "rgba(54, 162, 235, 0.2)",
            "rgba(255, 206, 86, 0.2)",
            "rgba(75, 192, 192, 0.2)",
            "rgba(153, 102, 255, 0.2)",
            "rgba(255, 159, 64, 0.2)",
          ],
          borderColor: [
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
            "rgba(255, 159, 64, 1)",
          ],
          borderWidth: 1,
        },
      ],
      StyleSheet:{
        height: 100,

      }
    });
  },
};
</script>
